import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormControl } from '@angular/forms';
import { TransactionPersonnelleEnum } from 'projects/box-lib/src/public-api';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-transaction-personnelle',
  templateUrl: './transaction-personnelle.component.html',
  styleUrls: ['./transaction-personnelle.component.scss'],
})
export class TransactionPersonnelleComponent {
  // those are defined by business and the id are from AtlasDB
  private readonly TRANSACTION_PERSONELLE_FULL_HABILITATION_IDS = [46, 47]; //CIF; ALPSI;
  private readonly TRANSACTION_PERSONNELLE_PARTIAL_HABILITATION_IDS = [44]; //ASSURANCE
  private _initValue: TransactionPersonnelleEnum | undefined;
  @Input({
    required: true,
  })
  set initValue(value: TransactionPersonnelleEnum | undefined) {
    if (this.isinitValueSet || this._initValue === value) {
      //do not launch init if already runned or if init value has not changed
      return;
    }
    this._initValue = value;
    this.isinitValueSet = true;
    this.initTransactionPersonnelle(value);
  }

  @Input({
    required: true,
  })
  set habilitationIds(value: Set<number>) {
    this.displaymode = 'no';
    this.isDisplaySet = false;
    if (this.TRANSACTION_PERSONNELLE_PARTIAL_HABILITATION_IDS.some(id => value?.has(id))) {
      this.displaymode = 'partial';
      this.isDisplaySet = true;
    }
    if (this.TRANSACTION_PERSONELLE_FULL_HABILITATION_IDS.some(id => value?.has(id))) {
      this.displaymode = 'full';
      this.isDisplaySet = true;
    }

    this.initTransactionPersonnelle(this._initValue);
  }

  @Output() onTransactionPersonnelleChange = new EventEmitter<TransactionPersonnelleEnum>();

  displaymode: 'no' | 'full' | 'partial' = 'no';
  toggleControl = new FormControl<boolean>(false);
  toggleSub: Subscription | undefined;
  radioSub: Subscription | undefined;
  radioControl = new FormControl<TransactionPersonnelleEnum>(TransactionPersonnelleEnum.Non);
  transactionOption = TransactionPersonnelleEnum;
  transactionValue: TransactionPersonnelleEnum;

  isDisplaySet = false;
  isinitValueSet = false;
  initTransactionPersonnelle(initValue: TransactionPersonnelleEnum | undefined) {
    if (!this.isDisplaySet) return;
    if (this.toggleSub) {
      this.toggleSub.unsubscribe();
    }
    if (this.radioSub) {
      this.radioSub.unsubscribe();
    }

    if (this.displaymode === 'full') {
      this.radioSub = this.radioControl.valueChanges.subscribe(value => {
        this.onTransactionPersonnelleChange.emit(value ?? undefined);
      });
      this.radioControl.setValue(initValue ?? this.transactionOption.Non);
      if (!initValue) {
        this.onTransactionPersonnelleChange.emit(this.transactionOption.Non);
      }
    }

    if (this.displaymode === 'partial') {
      if (initValue) {
        this.toggleControl.setValue(initValue === this.transactionOption.AutoSouscription);
        this.toggleSub = this.toggleControl.valueChanges.subscribe(value => {
          if (value) {
            this.onTransactionPersonnelleChange.emit(this.transactionOption.AutoSouscription);
          } else {
            this.onTransactionPersonnelleChange.emit(this.transactionOption.Non);
          }
        });
      } else {
        this.onTransactionPersonnelleChange.emit(this.transactionOption.Non);
      }
    } else {
      this.onTransactionPersonnelleChange.emit(undefined);
    }
  }
}
