import { Inject, Injectable } from '@angular/core';
import { Apollo, gql } from 'apollo-angular';
import { UserParametersService } from 'projects/box-lib/src/lib/services/user-parameters.service';
import { BehaviorSubject } from 'rxjs';
import { UserInfo } from '../models/UserInfo';
import {
  BackOfficeMember,
  Consultant,
  ConsultantHabilitation,
  Notification,
  UserInformations,
} from '../models/generated/graphql';
import { filterHabilitations } from '../utils/habilitation';

const notifyLogin = gql`
  mutation notifyUserLogin {
    notifyUserLogin {
      notifications {
        id
        message
        isActive
        consultantId
        backOfficeMemberId
        link
        linkLabel
      }
      backOfficeMember {
        id
        tokenId
        displayName
        groupeGestionnaire
        isAdmin
        isActive
        withGroupeAccessExtended
        serviceAllowedEnvelopeIds
      }
    }
  }
`;

const deleteNotificationByLink = gql`
  mutation deleteNotificationsByLink($link: String!) {
    deleteNotificationsByLink(link: $link)
  }
`;

const consultantUser = gql`
  query consultantByCode($code: String!) {
    consultantByCode(code: $code) {
      id
      code
      consultantHabilitations {
        id
        habilitationId
        dateDebut
        dateFin
        consultantId
        codeHabilitation
        statut
        habilitation {
          id
          code
          libelle
        }
      }
    }
  }
`;

// Class handling the login process, token decoding, and user information retrieval
@Injectable({
  providedIn: 'root',
})
export class AuthService {
  static token_sessionstorage_key = 'token';
  userInfo: any;
  isAuthenticatedValue = false;
  isBackOfficeValue = false;
  // isAdminValue = false;
  isAdminSubject = new BehaviorSubject<boolean>(false);
  environment: any;
  public readonly userBackOfficeSubject = new BehaviorSubject<BackOfficeMember | undefined>(undefined);
  public readonly userNotificationsSubject = new BehaviorSubject<Notification[]>([]);
  private userConsultantValidHabilitations: ConsultantHabilitation[] = [];
  private userConsultantExpiredHabilitations: ConsultantHabilitation[] = [];
  // consultantCheck: boolean;

  userHabilitationCheck: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  isInitializingLoginValue: boolean = true;
  constructor(
    private apollo: Apollo,
    @Inject('environment')
    environment: any,
    private userParametersService: UserParametersService
  ) {
    this.environment = environment;
  }

  async processToken() {
    // parse token && check authent
    this.parseToken();

    if (this.isAuthenticatedValue) {
      // after successful login, we notify the backend this use is connected. (no argument required, only token is used to recognize the user)
      this.apollo
        .mutate<{ notifyUserLogin: UserInformations }>({
          mutation: notifyLogin,
        })
        .subscribe(result => {
          this.userBackOfficeSubject.next(result.data?.notifyUserLogin?.backOfficeMember ?? undefined);
          this.userNotificationsSubject.next(result.data?.notifyUserLogin?.notifications ?? []);
        });

      if (!this.isBackOfficeValue) {
        this.getConsultantHabilitations();
      } else {
        this.userHabilitationCheck.next(true);
      }
    }
  }

  async deleteNotificationByLink(link: string | undefined) {
    if (!link) {
      return;
    }
    this.apollo
      .mutate<{ deleteNotificationsByLink: boolean }>({
        mutation: deleteNotificationByLink,
        variables: {
          link,
        },
      })
      .subscribe(result => {
        if (result.data?.deleteNotificationsByLink) {
          this.userNotificationsSubject.next(
            this.userNotificationsSubject.value.filter(notification => notification.link !== link)
          );
        }
      });
  }

  redirectToLogin() {
    const loginUrl = this.environment.authConfig.loginUrl;
    const clientId = this.environment.authConfig.clientId;
    const redirectUri = encodeURIComponent(window.location.href);
    window.location.replace(`${loginUrl}?response_type=token&client_id=${clientId}&redirect_uri=${redirectUri}`);
  }

  // Initiates the login process by checking if the access token is present in the URL hash.
  // If found, it stores the token in the session storage and parses it to extract user information.
  // If not found, it redirects the user to the authentication endpoint to obtain the access token.
  async login() {
    // if the access token is present in the URL hash we save it in the session storage and parse it
    const match = window.location.hash.match(/#access_token=(.*?)&/);
    if (match) {
      sessionStorage.setItem(AuthService.token_sessionstorage_key, match[1]);

      this.processToken();

      window.location.hash = '';
    } else {
      // if the access token is not present in the URL hash, At first we check if there is a token in the session storage
      if (sessionStorage.getItem(AuthService.token_sessionstorage_key)) {
        //there is a token in the session storage, we process it
        await this.processToken();

        return;
      }

      //if there is no token in the session storage we redirect the user to the authentication endpoint
      // before redirect we clean local storage to clear user info
      this.redirectToLogin();
    }
  }

  // Decodes a base64-encoded string to unicode
  b64DecodeUnicode(text: string) {
    return decodeURIComponent(
      Array.prototype.map.call(atob(text), c => '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2)).join('')
    );
  }

  // Decodes the JWT token payload by replacing characters and decoding the Base64 payload
  jwtDecode(tokenStringPayload: string) {
    return JSON.parse(this.b64DecodeUnicode(tokenStringPayload.replace('-', '+').replace('_', '/')));
  }

  // Parses the JWT token stored in the session storage and extracts the user information
  private parseToken() {
    const token = sessionStorage.getItem(AuthService.token_sessionstorage_key);
    const payload = token?.split('.')[1];
    if (!payload) {
      throw new Error('Cannot parse JWT');
    }

    this.userInfo = this.jwtDecode(payload);

    this.userParametersService.checkLocalStorageClear(this.userInfo?.authenticationDate);

    this.checkAuthent();
  }

  // Retrieves the JWT token from the session storage
  getToken(): string {
    const token = sessionStorage.getItem(AuthService.token_sessionstorage_key);
    if (token) {
      return token;
    } else {
      throw new Error('Not logged in');
    }
  }

  // Retrieves a specific code from the session storage
  getCode(): string {
    const code = sessionStorage.getItem('code');
    if (code) {
      return code;
    } else {
      throw new Error('Not logged in');
    }
  }

  // Retrieves the user information extracted from the JWT token.
  getUserInfo(): UserInfo {
    return {
      givenName: this.userInfo?.givenName,
      lastName: this.userInfo?.lastName,
      fullName: this.userInfo?.givenName + ' ' + (this.userInfo?.lastName ?? this.userInfo?.sn ?? ''),
      email: this.userInfo?.sub,
      tokenId: this.userInfo?.id,
      //consultantCode: this.userInfo?.consultantId
    };
  }

  isAdminRole(): boolean {
    return this.isBackOffice() && this.isAdminSubject?.getValue();
  }

  isInitLogin(): boolean {
    return this.isInitializingLoginValue;
  }

  isBackOffice(): boolean {
    return this.isBackOfficeValue;
  }

  getBackOfficeMember(): BackOfficeMember | undefined {
    return this.userBackOfficeSubject?.getValue() ?? undefined;
  }

  isAuthenticated(): boolean {
    return this.isAuthenticatedValue;
  }

  checkAuthent() {
    const backOfficeGroups = this.environment?.authConfig?.backOfficeGroups ?? [];
    const consultantGroups = this.environment?.authConfig?.consultantGroups ?? [];
    const backOfficeAdminGroups = this.environment?.authConfig?.backOfficeAdminGroups ?? [];

    this.isBackOfficeValue =
      this.userInfo?.memberOf?.filter(
        (value: string) => backOfficeGroups?.some((expectedValue: string) => value.includes(expectedValue)) ?? false
      )?.length > 0 ?? false;
    let isAdminValue =
      this.userInfo?.memberOf?.filter(
        (value: string) =>
          backOfficeAdminGroups?.some((expectedValue: string) => value.includes(expectedValue)) ?? false
      )?.length > 0 ?? false;
    this.isAdminSubject.next(isAdminValue);
    if (!this.isBackOfficeValue) {
      this.isAuthenticatedValue =
        this.userInfo?.memberOf?.filter(
          (value: string) => consultantGroups?.some((expectedValue: string) => value.includes(expectedValue)) ?? false
        )?.length > 0 ?? false;
    } else {
      this.isAuthenticatedValue = true;
    }
    this.isInitializingLoginValue = false;
  }

  getConsultantHabilitations() {
    this.apollo
      .query<{ consultantByCode: Consultant }>({
        query: consultantUser,
        variables: {
          code: this.userInfo?.id,
        },
      })
      .subscribe(result => {
        const userConsultant = result.data?.consultantByCode;
        if (userConsultant && userConsultant.consultantHabilitations) {
          const habs = filterHabilitations(userConsultant.consultantHabilitations);
          this.userConsultantValidHabilitations = habs.valid;
          this.userConsultantExpiredHabilitations = habs.expired;
          this.userHabilitationCheck.next(true);
        }
      });
  }
  logout(): void {
    sessionStorage.removeItem(AuthService.token_sessionstorage_key);
    window.location.href = this.environment.authConfig.logoutUrl;
  }

  getUserValidHabilitations(): ConsultantHabilitation[] {
    return this.userConsultantValidHabilitations;
  }

  getUserExpiredHabilitations(): ConsultantHabilitation[] {
    return this.userConsultantExpiredHabilitations;
  }

  getUserValidHabilitationIds(): number[] {
    if (!this.userConsultantValidHabilitations) {
      return [];
    }
    return this.userConsultantValidHabilitations.map(hab => hab.habilitationId);
  }

  isAnyHabilitationValid(): boolean {
    return this.userConsultantValidHabilitations?.length > 0;
  }

  resetAuthent() {
    this.isAuthenticatedValue = false;
    this.isBackOfficeValue = false;
    this.isAdminSubject.next(false);
    this.userConsultantValidHabilitations = [];
    this.userConsultantExpiredHabilitations = [];
    this.userHabilitationCheck.next(false);
    this.userBackOfficeSubject.next(undefined);

    sessionStorage.removeItem(AuthService.token_sessionstorage_key);
    this.login();
  }
}
