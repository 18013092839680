<div class="flex-vertical gap-16 width-100pc">
  <div>
    {{ totalCount }} <span *ngIf="totalCount > 1">utilisateurs</span><span *ngIf="totalCount < 2">utilisateur</span>
  </div>
  <div class="width-100pc">
    <oui-table>
      <table mat-table [dataSource]="dataSource">
        <ng-container matColumnDef="nom">
          <th mat-header-cell *matHeaderCellDef>Nom</th>
          <td mat-cell *matCellDef="let element">{{ element?.displayName }}</td>
        </ng-container>

        <ng-container matColumnDef="role">
          <th mat-header-cell *matHeaderCellDef>Rôle</th>
          <td mat-cell *matCellDef="let element">{{ element?.isAdmin ? 'Admin' : 'Gestionnaire' }}</td>
        </ng-container>

        <ng-container matColumnDef="group">
          <th mat-header-cell *matHeaderCellDef>Groupe par défaut</th>
          <td mat-cell *matCellDef="let element">
            <b *ngIf="element?.groupeGestionnaire === 'PSI'" class="color-stellium-financement-primary">PSI</b>
            <b *ngIf="element?.groupeGestionnaire === 'SVP'" class="color-stellium-immo-primary">SVP</b>
          </td>
        </ng-container>

        <ng-container matColumnDef="toggleExtendedAccess">
          <th mat-header-cell *matHeaderCellDef>Activer extension groupe</th>
          <td mat-cell *matCellDef="let element">
            <oui-slide-toggle
              label=""
              (checkedChange)="onUserRightsToggle(element)"
              [checked]="element?.withGroupeAccessExtended ?? false"
              (click)="$event.stopPropagation()">
            </oui-slide-toggle>
            <b
              [ngClass]="{
                'color-stellium-immo-primary':
                  element?.withGroupeAccessExtended && element?.groupeGestionnaire !== 'SVP',
                'color-stellium-financement-primary':
                  element?.withGroupeAccessExtended && element?.groupeGestionnaire !== 'PSI',
                'color-on-surface-disabled': !element?.withGroupeAccessExtended,
              }"
              >{{ element?.groupeGestionnaire === 'SVP' ? 'PSI' : 'SVP' }}</b
            >
          </td>
        </ng-container>

        <ng-container matColumnDef="deactivateButton">
          <th mat-header-cell *matHeaderCellDef></th>
          <td mat-cell *matCellDef="let element">
            <oui-button type="text" (click)="onDeactivateGestionnaire(element)">Supprimer</oui-button>
          </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <ng-container *ngIf="!isLoading">
          <tr mat-row [class.interactive]="true" *matRowDef="let row; columns: displayedColumns"></tr>
        </ng-container>
      </table>
      <oui-loading class="flex-vertical flex-center padding-vertical-16" *ngIf="isLoading"></oui-loading>
      <oui-paginator
        [pageSizeOptions]="pageSizeOptions"
        [length]="totalCount"
        [pageIndex]="pageIndex"
        [pageSize]="pageSize">
      </oui-paginator>
    </oui-table>
  </div>
</div>
