import { CurrencyPipe, DatePipe } from '@angular/common';

import { EnvoiEmailStatut, Maybe, Operation, OperationSortInput, SortEnumType } from '../../models/generated/graphql';
import { checkConsultantHabilitation } from '../../utils/habilitation';

export const MAJHeader = 'Mise à jour';

export abstract class OperationDashboardColumn {
  public header: string;
  public withSort: boolean = false;
  constructor(header: string, withSort: boolean = false) {
    this.withSort = withSort;
    this.header = header;
  }
  public abstract getDataFromOperation(
    operation: Operation,
    currencyPipe?: CurrencyPipe,
    datePipe?: DatePipe
  ): Maybe<string> | undefined;
  public getSortCriteria(isAsc: boolean): OperationSortInput | undefined {
    return undefined;
  }
}

export class InvestisseurDashboardColumn extends OperationDashboardColumn {
  constructor() {
    super('Investisseur');
  }

  public override getDataFromOperation(
    operation: Operation,
    currencyPipe?: CurrencyPipe,
    datePipe?: DatePipe
  ): Maybe<string> | undefined {
    const inv = operation.investisseurPreset?.displayName;
    if (inv && operation.coInvestisseurPreset) {
      return inv + '\n' + '& ' + operation.coInvestisseurPreset?.displayName;
    }
    return inv;
  }
}

export class ConsultantDashboardColumn extends OperationDashboardColumn {
  constructor() {
    super('Consultant');
  }

  public override getDataFromOperation(
    operation: Operation,
    currencyPipe?: CurrencyPipe,
    datePipe?: DatePipe
  ): Maybe<string> | undefined {
    var pp = operation?.consultantPreset;
    if (operation?.consultantPreset) {
      return operation?.consultantPreset?.nom + ' ' + operation?.consultantPreset.prenom;
    }
    return null;
  }
}

export class ProduitDashboardColumn extends OperationDashboardColumn {
  constructor() {
    super('Produit');
  }

  public override getDataFromOperation(
    operation: Operation,
    currencyPipe?: CurrencyPipe,
    datePipe?: DatePipe
  ): Maybe<string> | undefined {
    return operation?.produitPreset?.nomTechniqueProduitBox ?? operation?.produitPreset?.nom;
  }
}

export class EnveloppeDashboardColumn extends OperationDashboardColumn {
  constructor() {
    super('Enveloppe');
  }

  public override getDataFromOperation(
    operation: Operation,
    currencyPipe?: CurrencyPipe,
    datePipe?: DatePipe
  ): Maybe<string> | undefined {
    return operation?.produitPreset?.enveloppeLibelle;
  }
}

export class MontantDashboardColumn extends OperationDashboardColumn {
  constructor() {
    super('Montant', true);
  }

  public override getDataFromOperation(
    operation: Operation,
    currencyPipe?: CurrencyPipe,
    datePipe?: DatePipe
  ): Maybe<string> | undefined {
    if (currencyPipe == null) {
      return operation?.montant?.toString();
    }
    return currencyPipe.transform(operation?.montant, 'EUR');
  }

  public override getSortCriteria(isAsc: boolean): OperationSortInput | undefined {
    return { montant: isAsc ? SortEnumType.Asc : SortEnumType.Desc };
  }
}

export class GestionnaireDashboardColumn extends OperationDashboardColumn {
  constructor() {
    super('Gestionnaire');
  }

  public override getDataFromOperation(
    operation: Operation,
    currencyPipe?: CurrencyPipe,
    datePipe?: DatePipe
  ): Maybe<string> | undefined {
    return operation?.gestionnaire?.displayName ?? '-';
  }
}

export class NatureDashboardColumn extends OperationDashboardColumn {
  constructor() {
    super('Nature');
  }

  public override getDataFromOperation(
    operation: Operation,
    currencyPipe?: CurrencyPipe,
    datePipe?: DatePipe
  ): Maybe<string> | undefined {
    const libelle = operation?.operationConfig?.natureOperation?.libelle ?? '-';
    return libelle;
  }
}

export class MAJDashboardColumn extends OperationDashboardColumn {
  constructor() {
    super(MAJHeader, true);
  }

  public override getDataFromOperation(
    operation: Operation,
    currencyPipe?: CurrencyPipe,
    datePipe?: DatePipe
  ): Maybe<string> | undefined {
    if (datePipe == null) {
      return new Date(operation?.dateModification).getTime().toString();
    }
    return datePipe.transform(operation?.dateModification, 'dd/MM/yyyy');
  }

  public override getSortCriteria(isAsc: boolean): OperationSortInput | undefined {
    return { dateModification: isAsc ? SortEnumType.Asc : SortEnumType.Desc };
  }
}

export class DeclarationDashboardColumn extends OperationDashboardColumn {
  constructor() {
    super('Déclaration', true);
  }

  public override getDataFromOperation(
    operation: Operation,
    currencyPipe?: CurrencyPipe,
    datePipe?: DatePipe
  ): Maybe<string> | undefined {
    const declarationEntry = operation.dateDeclaration;
    if (declarationEntry) {
      return datePipe?.transform(declarationEntry, 'dd/MM/yyyy');
    }

    return undefined;
  }

  public override getSortCriteria(isAsc: boolean): OperationSortInput | undefined {
    return { dateDeclaration: isAsc ? SortEnumType.Asc : SortEnumType.Desc };
  }
}

export class StatutConsultantDashboardColumn extends OperationDashboardColumn {
  constructor() {
    super('Statut');
  }

  public override getDataFromOperation(
    operation: Operation,
    currencyPipe?: CurrencyPipe,
    datePipe?: DatePipe
  ): Maybe<string> | undefined {
    return operation?.statut?.consultantLibelle;
  }
}

export class StatutBackOfficeDashboardColumn extends OperationDashboardColumn {
  constructor() {
    super('Statut');
  }

  public override getDataFromOperation(
    operation: Operation,
    currencyPipe?: CurrencyPipe,
    datePipe?: DatePipe
  ): Maybe<string> | undefined {
    return operation?.statut?.backOfficeLibelle;
  }
}

export class WarningBackOfficeDashboardColumn extends OperationDashboardColumn {
  constructor() {
    super('Warning');
  }
  public override getDataFromOperation(
    operation: Operation,
    currencyPipe?: CurrencyPipe,
    datePipe?: DatePipe
  ): Maybe<string> | undefined {
    return checkConsultantHabilitation(
      operation.produitPreset?.habilitationCode,
      operation.consultantPreset?.habilitationCodes ?? []
    ) && !this.isErrorinEmail(operation)
      ? ''
      : 'warning';
  }

  private isErrorinEmail(operation: Operation): boolean {
    if (operation.emails?.length > 0) {
      return operation.emails.some(email => email.statutEnvoi === EnvoiEmailStatut.Error && !email.isErrorAcknowledged);
    }
    return false;
  }
}

export class DeleteDraftDashboardColumn extends OperationDashboardColumn {
  constructor() {
    super('DeleteDraft');
  }
  public override getDataFromOperation(
    operation: Operation,
    currencyPipe?: CurrencyPipe,
    datePipe?: DatePipe
  ): Maybe<string> | undefined {
    return operation.statutId < 10 ? 'delete' : '';
  }
}

export class PictoConsultantDashboardColumn extends OperationDashboardColumn {
  constructor() {
    super('Picto');
  }
  public override getDataFromOperation(
    operation: Operation,
    currencyPipe?: CurrencyPipe,
    datePipe?: DatePipe
  ): Maybe<string> | undefined {
    return operation?.commentaireGestionnaire;
  }
}
export class OperationIDDashboardColumn extends OperationDashboardColumn {
  constructor() {
    super('N° opé');
  }
  public override getDataFromOperation(
    operation: Operation,
    currencyPipe?: CurrencyPipe,
    datePipe?: DatePipe
  ): Maybe<string> | undefined {
    return operation?.id.toString();
  }
}
