<div class="param-layout">
  <h4>Utilisateurs</h4>
  <div class="flex-vertical oui-elevation-04 background-white rounded width-100pc padding-32 gap-40">
    <oui-searchbar
      class="width-100pc"
      placeholder="Rechercher un utilisateur"
      [control]="searchControl"></oui-searchbar>
    <app-utilisateurs-table
      [receivedData]="requestResult"
      (requestNewPage)="getAllUtilisateurs()"
      [(paginationParams)]="paginationParams">
    </app-utilisateurs-table>
  </div>
</div>
